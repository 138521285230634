/* metropolis-latin-400-normal */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-display: swap;
  font-weight: 400;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-400-normal.woff) format('woff');
}

/* metropolis-latin-500-normal */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-display: swap;
  font-weight: 500;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-500-normal.woff) format('woff');
}

/* metropolis-latin-600-normal */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-display: swap;
  font-weight: 600;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-600-normal.woff) format('woff');
}

/* metropolis-latin-700-normal */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-display: swap;
  font-weight: 700;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-700-normal.woff) format('woff');
}

/* metropolis-latin-800-normal */
@font-face {
  font-family: 'Metropolis';
  font-style: normal;
  font-display: swap;
  font-weight: 800;
  src:
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff2) format('woff2'),
    url(https://cdn.jsdelivr.net/fontsource/fonts/metropolis@latest/latin-800-normal.woff) format('woff');
}

.footer {
  font-family: 'Raleway', sans-serif;
  background-color: $black;
  // background-image: url("../assets/images/footer.jpg");
  // background-image: linear-gradient(270deg, #232526 35%, #414345 100%);
  position: relative;
  @media (max-width: 767px) {
    padding: 0 15px;
  }
  .footer_links {
    padding: 70px 0;
    .casino_text {
      color: #ffffff;
      font-family: 'Raleway', Sans-serif;
      font-size: 16px;
      font-weight: 400;
    }
    .iso {
      font-weight: 400;
      font-size: 18px;
      span {
        font-weight: 500;
      }
      color: white;
    }
    ul {
      margin: 0;
      padding: 0;
      li {
        list-style: none;
        // color: $link_color;
        font-size: 15px;
        text-transform: capitalize;
        padding: 5px 0;
        font-weight: 600;
        a {
          font-size: 17px;
          font-weight: 400;
          line-height: 30px;
          color: #ffffff;
        }
        &.social_links {
          display: flex;
          gap: 15px;
          .icon_area {
            background: $theme_color;
            height: 40px;
            width: 40px;
            border-radius: 50%;
            text-align: center;
            line-height: 38px;
            color: $white;
            font-weight: 400;
            font-size: 20px;
            // line-height: 20px;
          }
        }
        .contact_group {
          padding: 5px 0 10px 0;
          border-bottom: 1px solid $theme_color;
          display: flex;
          gap: 25px;
          align-items: center;
          text-transform: none;
          justify-content: flex-start;
          .icon_area {
            background: #d9121f;
            border-radius: 50%;
            color: #fff;
            font-size: 20px;
            font-weight: 400;
            height: 40px;
            line-height: 40px;
            text-align: center;
            width: 40px;
          }
          .text_double {
            // max-width: 221px;
            // min-width: 220px;
            @media (min-width: 1201px) and (max-width: 1400px) {
              max-width: 191px;
              min-width: 190px;
            }
            @media (min-width: 992px) and (max-width: 1200px) {
              max-width: 141px;
              min-width: 140px;
            }
            .option_name {
              // font-family: 'Raleway';
              @media (min-width: 1201px) and (max-width: 1400px) {
                word-wrap: normal;
              }
              color: #e2e2e2;
              font-family: 'Raleway', Sans-serif;
              font-size: 14px;
              font-weight: 400;
              &.value {
                // font-family: "sans-serif";
                color: #ffffff;
                font-family: 'Raleway', Sans-serif;
                font-size: 17px;
                font-weight: 700;
                a {
                  color: #ffffff;
                  font-family: 'Raleway', Sans-serif;
                  font-size: 18px;
                  font-weight: 700;
                  font-family: 'Metropolis' !important;
                }
              }
            }
          }
          @media (max-width: 575px) {
            display: block;
          }
        }
        .no_border {
          border-bottom: none;
        }
        &.casino_text {
          text-transform: none;
        }
        &.list_heading {
          font-weight: 700;
          font-size: 20px;
          color: white;
        }
        .red_dash {
          margin-top: 5px;
          margin-bottom: 15px;
          height: 3px;
          background: $theme_color;
          width: 45px;
          display: inline-block;
          &.white {
            margin-left: 4px;
            height: 3px;
            background: $white;
            width: 8px;
            display: inline-block;
          }
        }
        a {
          text-decoration: none;
          // color: $link_color;
          &:hover {
            color: $theme_color;
          }
          &.active {
            color: $theme_color;
          }
          .footer_logo {
            max-height: 100px;
            width: 50%;
            margin-left: 20px;
            @media (max-width: 575px) {
              display: flex;
              margin: auto;
            }
          }
        }
      }
    }
    .isMobileRemove {
      @media (max-width: 767px) {
        display: none;
      }
    }
  }

  .copyright_section {
    border-top: 1px solid $link_color;
    .copyright_text {
      color: $white;
      text-align: center;
      font-size: 16px;
      padding: 30px 0;
      font-weight: 500;
    }
  }
  .scrolling_up {
    position: absolute;
    bottom: 15px;
    right: 10px;
    text-align: -webkit-right;
    // text-align: right;

    .whatsapp {
      height: 80px;
      width: 80px;
      border-radius: 50%;
      background: $white;
      font-size: 50px;
      color: #4fce5d;
      text-align: center;
      position: relative;
      line-height: 80px;
      .status {
        height: 10px;
        width: 10px;
        background: $theme_color;
        position: absolute;
        top: 0px;
        right: 12px;
        border-radius: 50%;
      }
    }
    .scroll_top {
      background: #191919;
      height: 46px;
      width: 46px;
      border-radius: 50%;
      color: $white;
      font-size: 25px;
      text-align: center;
      margin: 0;
      line-height: 45px;

      position: fixed;
      bottom: 0.5%;
      right: 0.5%;
      transform: scale(0);
      transition: 350ms ease 0s;
      &.on {
        transform: scale(1);
      }
      i {
        font-size: 18px;
        font-weight: 400;
      }
    }
    .scroll_top:hover {
      background: $theme_color;
    }
  }
}
