div {
    font-family: 'Raleway', sans-serif;
}

.PaintToolEdgeMarker {
    width: 20px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    border-radius: 2px;
    box-shadow: 1px 1px 1px 1px rgb(0 0 0 / 40%);
    z-index: 100;
}

.PaintToolEdgeLine {
    height: 2px;
    position: absolute;
    background-color: #fff;
    transform-origin: 0% 0%;
    box-shadow: 0 0 1px 1px rgb(0 0 0 / 40%);
    z-index: 99;
}

.PaintToolEdgeRemove {
    width: 24px;
    height: 24px;
    cursor: pointer;
    position: absolute;
    border-radius: 24px;
    border: 1px solid rgba(255,255,255,.49);
    background-color: #b3301e;
    z-index: 100;
}

.PaintToolMarkerEraser {
    width: 30px;
    height: 30px;
    cursor: pointer;
    float: left;
    border-radius: 15px;
    position: absolute;
    color: #fff;
    box-shadow: 0 0 4px rgb(255 255 255 / 49%);
}


