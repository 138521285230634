$theme_color: #d9121f;
$heading_color: #3c3c3c;
$site_bg: #fbfbfb;
$link_color :#D1D1D1;




$theme_color_deep: #9460e9;

$theme_color_light: #9b5fec;
$white: rgb(255, 255, 255);
$yellow: #fee600;

$black_bg: #191919;
$black: #000000;

$dark_blue_bg: #0e1830;
$footer_bg: $dark_blue_bg;
$header_bg: linear-gradient(to right, #10294f, #1d3b6a);

$input_bg: $black_bg;

$common_text_color: #7d8386;
$green: rgb(52, 196, 117);
$green_highlight: rgb(41, 156, 93);
$background_blue_dark: #050a1e;
$light_blue: rgb(77, 166, 234);
$light_blue_highlight: rgb(33, 113, 206);

$dark_white: #c7cbe4;
$dark_blue: #050a1e;

$custom-hover-color: #4de8ff;
$social-icon-hover-color: #bf40bf;

@import url("https://fonts.googleapis.com/css?family=Inconsolata:700");
