.app_header {
  align-items: center;
  position: relative;
  border-bottom: 1px solid rgba(230, 230, 230, 1);
  font-family: 'Raleway', sans-serif;

  &.to_fixed {
    // position: fixed;
    // top: 0;
    width: 100%;
    box-shadow: 1px 4px 4px rgb(0 0 0 / 27%) !important;
    z-index: 1111;
  }

  .expand_searchbox {
    position: absolute;
    top: 80px;
    right: 50px;
    border-radius: 0px;
    z-index: 3333;
    .expand_input {
      background: #d3d3d3;
      border: 0px;
      padding: 8px 15px;
      border-radius: 3px;
      outline: 0px;
      color: #657b83;
      font-size: 17px;
      &:focus {
        outline: none;
        box-shadow: none;
        border: none;
      }
    }
    .close_search_bar {
      position: absolute;
      right: 5px;
      top: 10px;
      cursor: pointer;
      color: $theme_color;
      opacity: 1;
      height: 20px;
      width: 20px;
      background: #d3d3d3;
      z-index: 2;
      font-size: 20px;
      text-align: center;
    }
  }
  .header_topbar {
    font-family: inherit;
    height: 30px;
    background: $white;
    text-align: center;
    font-size: 14px;
    color: $black;
    font-weight: 700;
    line-height: 30px;
    position: relative;

    .top_remove {
      position: absolute;
      right: 15px;
      top: 10px;
      cursor: pointer;
    }
  }
  .app_header_menu {
    padding: 10px 92px 20px 100px;
    height: 90px;
    background-color: $theme_color;
    display: grid;
    grid-template-columns: 0.5fr 2.5fr 0.5fr;
    // justify-content: space-between;
    flex-grow: 1;
    align-items: center;
    margin: 0;
    position: relative;
    @media only screen and (max-width: 1600px) {
      padding: 20px 60px;
      grid-template-columns: 0.6fr 2.5fr 0.3fr;
    }
    @media (max-width: 575px) {
      height: 90px;
      padding: 10px 20px;
      grid-template-columns: auto;
    }
    .mainvan {
      justify-content: center;
    }
    .mbnavld {
      position: relative;
      @media only screen and (max-width: 575px) {
        // grid-gap: 50%;
        justify-content: center;
      }
      a.mbnavlink,
      .mnlogo {
        position: absolute;
        left: 0px;
      }
    }
    .menu-group {
      display: flex;
      // justify-content: space-between;
      align-items: center;
      gap: 42px;
      grid-gap: 35px;
      a {
        text-decoration: none;
        // color: rgba(25,25,25,1);
        color: $white;
        gap: 30px;
        text-transform: uppercase;
        font-size: 17px;
        font-weight: 600;
        line-height: 22px;
        font-family: 'Raleway';
        &:hover {
          color: #040000;
        }
        &.active {
          color: #040000;
        }
        .app_logo {
          // text-align: center;
          width: 140px;
          height: 58.16px;
          img {
            // max-height: 70px;
            // padding-left: 70px;
            // max-width: 130px;
            //   max-height: 58.16px;
            max-width: 100%;
          }
        }
      }
    }
    .search-login {
      justify-content: flex-end;
      padding-right: 85px;
    }
  }
}
