.left_side {
  // z-index: 10;
  font-family: 'Raleway', sans-serif;
  background: $white;
  height: 100%;
  padding: 10px 0 25px 40px;
  .left_sidebar_menus {
    // margin-top: 40px;
    ul {
      margin: 0;
      padding: 0;

      li {
        margin-bottom: 8px;
        list-style: none;
        a {
          margin-top: 10px;
          text-decoration: none;
          text-transform: uppercase;
          color: $dark_blue;
          display: block;
          // height: 22px;
          line-height: 22px;
          font-size: 17px;
          font-family: Raleway;
          font-style: normal;
          font-weight: 600;
          width: 100%;
          opacity: 1;
          &.active {
            color: $theme_color;
          }
          &:hover {
            color: $theme_color;
          }
        }
      }
    }
  }
}
