.app_tabs {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-bottom: 36px;
    gap: 20px;

    .app_tab_item {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        // margin: 0 20px 0;
        gap: 16px;
        flex: 1 0 0;
        cursor: pointer;
        background-color: #f5f5f5;
        border-radius: 999px;

        .tab_taxt {
            color: $black;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 110% */
        }

        .tab_circle_box {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 9999px;
            background: $black;
        }

        .tab_circle_text {
            color: $white;
            font-family: 'Jost', sans-serif;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 91.667% */
            text-transform: uppercase;
        }

        // &:hover {
        //     border-radius: 999px;
        //     border-bottom: 4px solid rgba(217, 18, 31, 0.10);
        //     background: #D9121F;

        //     .tab_taxt {
        //         color: $white;
        //     }

        //     .tab_circle_text {
        //         color: $theme_color;
        //     }

        //     .tab_circle_box {
        //         background: $white;
        //     }
        // }
    }

    .app_tab_item_active {
        display: flex;
        padding: 16px;
        justify-content: center;
        align-items: center;
        gap: 16px;
        flex: 1 0 0;
        border-radius: 999px;
        border-bottom: 4px solid rgba(217, 18, 31, 0.1);
        background: #d9121f;
        cursor: pointer;
        .tab_taxt {
            color: $white;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 110% */
        }

        .tab_circle_box {
            display: flex;
            width: 32px;
            height: 32px;
            padding: 10px;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            gap: 10px;
            border-radius: 9999px;
            background: $white;
        }

        .tab_circle_text {
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 91.667% */
            text-transform: uppercase;
            color: $theme_color;
        }
    }

    @media (max-width: 759px) {
        .app_tab_item {
            .tab_taxt {
                font-size: 16px;
            }

            .tab_circle_box {
                width: 28px;
                height: 28px;
                flex-direction: unset;
                padding: 11px 5px;
            }
            .tab_circle_box {
                font-size: 16px;
                line-height: 18px;
            }
        }
        .app_tab_item_active {
            padding: 10px;
            .tab_taxt {
                font-size: 16px;
            }

            .tab_circle_box {
                width: 28px;
                height: 28px;
            }
            .tab_circle_box {
                font-size: 16px;
                line-height: 18px;
            }
        }
    }
}
