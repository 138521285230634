@import url('https://fonts.googleapis.com/css2?family=Jost:ital,wght@0,200;0,300;0,400;0,500;0,600;0,700;1,200;1,300;1,400;1,500;1,600;1,700&display=swap');
.panelOne {
    // margin: 0 20px 30px 20px;

    .body {
        border-radius: 24px;
        background: #f5f5f5;
        width: 100%;
        min-height: 550px;
        margin-bottom: 20px;
        justify-content: center;
        align-items: center;
        display: flex;

        .panel-item {
            cursor: pointer;
        }

        .panel-item-image {
            width: 413px;
            height: 295px;
            border-radius: 16px;
        }

        .panel-item-box {
            width: 415px;
            height: 296px;
            flex-shrink: 0;
            border-radius: 16px;
            display: flex;
            justify-content: center;
            flex-direction: column;
            align-items: center;
            border: 2px dashed #2252f5;
            background: rgba(34, 82, 245, 0.1);

            .panel-item-box-titel {
                color: #2252f5;
                text-align: center;
                font-family: Raleway;
                font-size: 18px;
                font-style: normal;
                font-weight: 700;
                line-height: 22px;
                /* 122.222% */
            }

            .panel-item-box-subtitle {
                color: #2252f5;
                text-align: center;
                font-family: Raleway;
                font-size: 14px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 157.143% */
            }
        }

        .panel-item-text {
            color: #000;
            font-family: Raleway, sans-serif;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 110% */
        }

        .panel-item-subtitle {
            color: #595959;
            text-align: center;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 400;
            line-height: 22px;
            /* 137.5% */
        }

        .panel-item-or {
            margin: 0 64px 0 64px;
            color: #000;
            font-family: Raleway;
            font-size: 24px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            /* 91.667% */
        }

        .panel-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            padding: 10px 0 0 10px;
        }
    }
}

.panelTwo {
    margin-top: 56px;
    margin-bottom: 103px;

    .panel-two-body-content {
        display: flex;
        min-height: 734px;
        width: 100%;
        padding: 32px 24px;
        align-items: flex-start;
        border-radius: 24px;
        background: #f5f5f5;
    }

    .panel-two-category-item {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;

        .panel-two-ctg-text {
            color: #000;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 110% */
        }
    }

    .panel-two-category-list {
        display: flex;
        width: 273px;
        padding: 16px 0px;
        flex-direction: column;
        align-items: flex-start;
        gap: 16px;
        flex-shrink: 0;
    }

    .panel-two-category {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        cursor: pointer;

        .panel-two-category-text {
            color: #000;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 110% */
        }

        &:hover {
            background-color: $white;

            .panel-two-category-text {
                color: $theme_color;
            }
        }
    }

    .panel-two-category-active {
        display: flex;
        padding: 16px;
        align-items: center;
        gap: 10px;
        align-self: stretch;
        background-color: $white;
        cursor: pointer;

        .panel-two-category-text {
            color: $theme_color;
            font-weight: 500;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            line-height: 22px;
            /* 110% */
        }
    }

    .panel-two-subcatgory {
        display: flex;
        padding: 24px;
        width: 100%;
        min-height: 670px;
        flex-direction: column;
        align-items: flex-start;
        gap: 32px;
        flex: 1 0 0;
        border-radius: 24px;
        background: #fff;
        cursor: pointer;

        .panel-two-grid-list {
            display: grid;
            grid-template-columns: repeat(4, 1fr);
            grid-gap: 10px 10px;

            .panel-item-grid-item {
                height: 194px;
                width: 100%;
                flex: 1 0 0;
                border-radius: 16px;
                background: #ffffff;
                background-image: url('../assets/images/Loading_2.gif');
                background-size: 70px 70px;
                background-position: center;
                background-repeat: no-repeat;

                .panel-item-grid-item-img {
                    border-radius: 16px;
                    width: 100%;
                    height: 194px;
                }

                &:hover {
                    .panel-item-grid-item-img {
                        border: 4px solid #d9121f;
                    }
                }
            }

            .panel-item-grid-item-active {
                height: 194px;
                width: 100%;
                flex: 1 0 0;
                border-radius: 16px;
                background: #ffffff;
                background-image: url('../assets/images/Loading_2.gif');
                background-size: 70px 70px;
                background-position: center;
                background-repeat: no-repeat;

                .panel-item-grid-item-img {
                    border-radius: 16px;
                    width: 100%;
                    height: 194px;
                    border: 4px solid #d9121f;
                }
            }
        }
    }

    .panel-two-darg-file-section {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;
        width: 100%;
        min-height: 700px;

        .panel-two-darg-drop-box {
            text-align: center;
            padding: 20px;
            border: 1px #b3301e dashed;
            width: 100%;
            height: 400px;
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            background-color: #fafafa;
            border-radius: 12px;
            margin: auto;
        }

        .panel-two-upload-item {
            background-color: $white;
            border-radius: 16px;
            padding: 10px;
            width: 100%;
            height: 100px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .panel-two-upload-img {
                margin-right: 10px;
                width: 100px;
                height: 80px;
                border-radius: 16px;
            }

            .panel-two-upload-text-section {
                display: flex;
                flex-direction: column;
                justify-content: start;

                .panel-two-upload-text {
                    color: #000;
                    font-family: Jost;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 600;
                    line-height: 22px; /* 110% */
                }
                .panel-two-upload-text-size {
                    color: #000;
                    font-family: Jost;
                    font-size: 14px;
                    font-style: normal;
                    font-weight: 400;
                    line-height: 16px; /* 110% */
                }
            }
        }
    }
}

.panel-two-toolbar {
    margin-top: 20px;
    margin-bottom: 40px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;

    .panel-two-upload {
        display: flex;
        width: 270px;
        //height: 46px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 99999px;
        border: 1px solid #d9121f;
        cursor: pointer;

        .panel-two-upload-text {
            color: #d9121f;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            margin-bottom: 0px;
            /* 137.5% */
        }

        .panel-two-upload-img {
            width: 20px;
            height: 20px;
            flex-shrink: 0;
        }
    }

    .panel-two-back {
        display: flex;
        //width: 270px;
        //height: 46px;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        flex-shrink: 0;
        border-radius: 99999px;
        border: 1px solid #d9121f;
        cursor: pointer;

        .panel-two-back-text {
            color: #d9121f;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 137.5% */
        }
    }

    .panel-two-title {
        color: #000;
        font-family: Raleway;
        font-size: 24px;
        font-style: normal;
        font-weight: 600;
        line-height: 22px;
        margin-bottom: 0px;
        /* 91.667% */
    }

    .panel-two-color-btn {
        display: flex;
        padding: 12px 16px;
        justify-content: center;
        align-items: center;
        gap: 8px;
        border-radius: 99999px;
        background: #d9121f;
        cursor: pointer;

        .panel-two-color-btn-text {
            color: #fff;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px;
            /* 137.5% */
        }
    }
}

.panel-three {
    margin-bottom: 103px;

    .body {
        border-radius: 24px;
        background: #f5f5f5;
        width: 100%;
        padding: 32px 24px;
        min-height: 775px;
        display: flex;
        flex-direction: column;

        .search-section {
            display: flex;
            margin-top: 32px;
            justify-content: center;
            align-items: center;
            width: 100%;

            .search-box {
                display: flex;
                width: 40%;
                padding: 16px;
                align-items: flex-start;
                gap: 24px;
                border-radius: 999px;
                border: 1px solid #adadad;
                background: #fff;

                .vertical-line {
                    width: 1px;
                    height: 20px;
                    background: #b6b6b6;
                }

                .search-input {
                    color: $black;
                    font-family: Raleway;
                    font-size: 20px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    border: 0;
                    width: 100%;
                    /* 110% */

                    &::placeholder {
                        color: #5c5959;
                        font-family: Raleway;
                        font-size: 20px;
                        font-style: normal;
                        font-weight: 500;
                        line-height: 22px;
                    }

                    &:focus {
                        outline: none;
                    }
                }
            }
        }

        .color-category-list-section {
            margin-top: 33px;
            display: flex;
            justify-content: space-around;
            align-items: center;

            .color-category-item {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                padding: 12px 16px;
                border-radius: 16px;

                .color-seaction {
                    height: 50px;
                    width: 51px;
                    border-radius: 50%;
                    position: relative;

                    .color_frame {
                        position: absolute;
                        top: -5px;
                        left: -8px;
                        height: 60px;
                        width: 67px;
                    }

                    .checkmark {
                        visibility: hidden;
                        font-size: 18px;
                        color: $white;
                        display: block;
                        border-radius: 50%;
                        text-align: center;
                        margin: 12px;
                    }
                }

                .color-category-item-title {
                    margin-top: 20px;
                    color: #000;
                    font-family: Raleway;
                    font-size: 16px;
                    font-style: normal;
                    font-weight: 500;
                    line-height: 22px;
                    /* 137.5% */
                }

                &:hover {
                    // background: rgba(240, 143, 169, 0.8);
                    background: #dee2e6;
                    .color-seaction {
                        .color_frame {
                            transform: scale(1);
                            -webkit-transition: transform 0.5s ease-in-out;
                        }

                        .checkmark {
                            visibility: visible;
                        }
                    }

                    .color-category-item-title {
                        font-weight: 700;
                    }
                }

                &.active {
                    //  background: rgba(240, 143, 169, 0.8);
                    background: #dee2e6;
                    .color-seaction {
                        .color_frame {
                            transform: scale(1.2);
                            -webkit-transition: transform 0.5s ease-in-out;
                        }

                        .checkmark {
                            visibility: visible;
                        }
                    }

                    .color-category-item-title {
                        font-weight: 700;
                    }
                }
            }
        }

        .sub-category-section {
            display: flex;
            flex-direction: row;
            justify-self: start;
            margin-top: 17px;

            .panel-three-subcategory-body {
                display: flex;
                padding: 24px;
                width: 100%;
                min-height: 670px;
                flex-direction: column;
                align-items: flex-start;
                gap: 32px;
                flex: 1 0 0;
                border-radius: 24px;
                background: #fff;

                .panel-three-subcategory-grid-list {
                    display: grid;
                    grid-template-columns: repeat(5, 1fr);
                    grid-gap: 10px 10px;

                    .panel-three-grid-item {
                        display: flex;
                        width: 183px;
                        height: 183px;
                        padding: 16px;
                        position: relative;
                        justify-content: center;
                        border-radius: 16px;
                        cursor: pointer;

                        .panel-three-grid-item-title {
                            position: absolute;
                            bottom: 55px;
                            color: #000;
                            text-align: center;
                            font-family: Jost;
                            font-size: 14px;
                            font-style: normal;
                            font-weight: 600;
                            line-height: normal;
                        }

                        .panel-three-grid-code-section {
                            position: absolute;
                            right: 16px;
                            left: 16px;
                            bottom: 16px;
                            display: flex;
                            margin-bottom: 10;
                            padding: 4px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 10px;
                            align-self: stretch;
                            border-radius: 999px;
                            background: #fff;

                            .panel-three-grid-item-code {
                                color: #000;
                                text-align: center;
                                font-family: Jost;
                                font-size: 16px;
                                font-style: normal;
                                font-weight: 600;
                                line-height: normal;
                            }
                        }

                        .panel-three-grid-checkmark {
                            font-size: 40px;
                            color: $white;
                            display: block;
                            border-radius: 50%;
                            text-align: center;
                            position: absolute;
                            left: calc(50% - 15px);
                            top: 25%;
                            z-index: 1;

                            &.text-dark {
                                color: #000000 !important;
                            }
                        }

                        // &:hover {
                        //     .panel-three-grid-item-title {
                        //         top: 20px;
                        //         bottom: 0px;
                        //     }
                        // }
                    }
                }
            }
        }
    }
}

.panel-selected-colors {
    display: flex;
    width: 273px;
    padding: 16px 0px;
    flex-direction: column;
    align-items: flex-start;
    gap: 16px;
    flex-shrink: 0;

    .panel-total-count-section {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: stretch;
        gap: 16px;
        flex-shrink: 0;
        width: 100%;
        padding-right: 20px;

        .panel-total-text {
            color: #000;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 700;
            line-height: 22px;
            /* 110% */
        }

        .panel-total-count-text {
            color: #000;
            font-family: Raleway;
            font-size: 20px;
            font-style: normal;
            font-weight: 600;
            line-height: 22px;
            /* 110% */
        }
    }

    .panel-selected-color-item {
        display: flex;
        padding: 12px;
        justify-content: space-between;
        align-items: center;
        align-self: stretch;
        border-radius: 8px;
        background: #fff;
        gap: 16px;
        flex-shrink: 0;
        width: 100%;
        margin-left: 16px;
        margin-bottom: 16px;
        cursor: pointer;

        .panel-color-content {
            display: flex;
            justify-content: start;
            align-items: center;
            align-self: stretch;
            gap: 16px;

            .panel-color-avater-section {
                width: 46px;
                height: 46px;
                position: relative;
                justify-content: center;

                .panel-color-avater {
                    width: 46px;
                    height: 46px;
                    border-radius: 50px;
                    margin-right: 10px;
                    position: absolute;
                }

                .avater-selected-checkmark {
                    visibility: hidden;
                    font-size: 18px;
                    font-weight: 800;
                    color: $white;
                    display: block;
                    border-radius: 50%;
                    text-align: center;
                    margin: 12px;
                    position: absolute;
                }
                .selectd-avater-active {
                    visibility: visible;
                    font-size: 18px;
                    font-weight: 800;
                    color: $white;
                    display: block;
                    border-radius: 50%;
                    text-align: center;
                    margin: 12px;
                    position: absolute;
                }
            }

            .panel-color-title {
                color: #000;
                font-family: Jost;
                font-size: 20px;
                font-style: normal;
                font-weight: 600;
                line-height: 22px;
                /* 110% */
            }

            .panel-color-subtitle {
                color: #000;
                font-family: Jost;
                font-size: 16px;
                font-style: normal;
                font-weight: 400;
                line-height: 22px;
                /* 137.5% */
                /* 110% */
            }
        }

        .panel-color-remove {
            width: 24px;
            height: 24px;
            cursor: pointer;
        }

        &:hover {
            .panel-color-content {
                .panel-color-title {
                    color: $light_blue;
                }
            }
        }
    }

    .panel-add-color-btn {
        display: flex;
        padding: 12px 16px;
        margin-left: 20px;
        justify-content: center;
        align-items: center;
        flex-direction: row;
        gap: 16px;
        align-self: stretch;
        border-radius: 999px;
        border: 1px solid #d9121f;
        cursor: pointer;

        .panel-add-text-btn {
            color: #d9121f;
            font-family: Raleway;
            font-size: 16px;
            font-style: normal;
            font-weight: 500;
            line-height: 22px; /* 137.5% */
        }
    }
}

.panel-four {
    margin-bottom: 103px;

    .body {
        border-radius: 24px;
        background: #f5f5f5;
        width: 100%;
        padding: 32px 24px;
        min-height: 775px;
        display: flex;
        flex-direction: column;

        .panel-four-sub-category-section {
            display: flex;
            flex-direction: row;
            justify-self: start;
            margin-top: 17px;

            .panel-four-drow-secion {
                display: flex;
                padding: 24px;
                width: 100%;
                min-height: 670px;
                flex-direction: column;
                align-items: center;
                gap: 32px;
                // flex: 1 0 0;
                border-radius: 24px;
                background: #fff;

                .panel-four-draw-toolbar {
                    display: flex;
                    flex-direction: row;
                    justify-content: space-between;
                    align-items: stretch;
                    gap: 16px;
                    min-width: 960px;

                    .panel-four-center-section {
                        display: flex;
                        width: 40%;
                        justify-content: center;
                        align-items: flex-start;
                        align-self: stretch;
                        gap: 16px;
                    }

                    .panel-four-tool-btn {
                        display: flex;
                        padding: 12px 16px;
                        justify-content: center;
                        align-items: center;
                        gap: 8px;
                        cursor: pointer;
                        border-radius: 99999px;
                        border: 1px solid #000;

                        .panel-four-tool-btn-text {
                            color: #000;
                            font-family: Raleway;
                            font-size: 16px;
                            font-style: normal;
                            font-weight: 500;
                            line-height: 22px; /* 137.5% */
                        }
                    }
                }
                .uploaded_photo {
                    background: url('../assets/images/frame-bg.png');
                    text-align: center;

                    &.no_bg {
                        background: #ffffff;
                    }

                    img {
                        width: 100%;
                        margin: 0 auto;
                        overflow: hidden;
                        height: 100%;
                        border-radius: 16px;

                        @media (max-width: 767px) {
                            width: 100% !important;
                            height: auto;
                        }
                    }
                    .paint-canvas {
                    }
                }

                .panel-four-edit-section {
                    display: flex;
                    //flex-direction: row;
                    justify-self: center;
                    align-items: center;
                    width: 500px;

                    .panel-four-edit-btn {
                        margin: 0 10px 10px 0;
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        flex-direction: column;

                        .panel-four-edit-icon {
                            display: flex;
                            padding: 12px 16px;
                            justify-content: center;
                            align-items: center;
                            gap: 8px;
                            cursor: pointer;
                            border-radius: 99999px;
                            border: 1px solid #000;
                        }

                        &:hover {
                            .panel-four-edit-icon {
                                border: 1px solid $theme_color;
                            }

                            p {
                                color: $theme_color;
                            }
                        }
                    }
                }
            }
        }
    }
}

.screenshot-bottom-section {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    padding-left: 10%;
    visibility: hidden;

    .water-mark-image {
        background-image: url('../assets/images/logo.png');
        background-repeat: no-repeat;
        background-size: contain;
        height: 70px;
        padding-right: 30%;
    }

    .screenshot-bottom-contact-group {
        display: flex;
        flex-direction: row;
        align-items: center;
        text-transform: none;
        justify-content: flex-start;
        gap: 25px;

        .s_title {
            font-family: 'Raleway', Sans-serif;
            font-size: 16px;
            font-weight: 800;
        }
        .screenshot-bottom-text-double {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
            .s_icon_area {
                font-size: 20px;
                font-weight: 400;
                height: 40px;
                line-height: 40px;
                text-align: center;
                width: 40px;
            }
            .s_option_name {
                font-family: 'Metropolis', Sans-serif;
                font-size: 14px;
                font-weight: 400;
            }
        }
    }
}

.panel-preview {
    display: flex;
    flex-direction: column;

    .photo_edit_frame {
        min-height: 300px;
        // min-width: 600px;
        overflow: hidden;
        border: 1px solid #ccc;

        @media (max-width: 767px) {
            min-width: 100% !important;
            height: auto;
        }

        .frame_header {
            background: #ffffff;
            display: flex;
            justify-content: space-between;
            border-bottom: 1px solid #ccc;
            align-items: center;
            padding: 10px;
        }

        .uploaded_photo {
            background: url('../assets/images/frame-bg.png');
            text-align: center;

            &.no_bg {
                background: #ffffff;
            }

            img {
                width: 75%;
                margin: 0 auto;
                overflow: hidden;
                height: 100%;

                @media (max-width: 767px) {
                    width: 100% !important;
                    height: auto;

                    &.profile_image {
                        width: 100% !important;
                    }
                }
            }
        }
        .frame_header_image_upload {
            .header_tabs {
                background-color: #ccc;
                align-items: center;
                display: flex;
                .single_menu {
                    min-height: 50px;
                    min-width: 77px;
                    line-height: 50px;
                    padding: 0 10px;
                    text-align: center;
                    align-content: center;
                    font-weight: 700;
                    color: #666;
                    cursor: pointer;
                    .pad_right {
                        padding-right: 7px;
                    }
                    &.active {
                        background-color: #ffffff;
                        color: #000000;
                    }
                    &:hover {
                        color: #000000;
                    }
                }
            }
            .header_content {
                color: #666;
                .preview_button {
                    border: none;
                    outline: none;
                    background-color: #f60;
                    color: #ffffff;
                    border-radius: 10px;
                    padding: 7px;
                    margin-right: 10px;
                }
                .paint_area_content {
                    padding-left: 15px;
                    min-height: 50px;
                    display: flex;
                    align-items: center;
                    span {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media only screen and (max-width: 1399px) {
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 150px;
    }
}

@media only screen and (max-width: 1199px) {
    .panelOne .body .panel-item-image {
        width: 100%;
        height: auto;
    }
    .panelOne .body {
        width: 100%;
        min-height: auto;
        padding: 20px;
    }
    .panelOne .body .panel-item-box {
        height: auto;
        padding: 50px;
        width: 300px;
    }
    .panel-three .body .sub-category-section .panel-three-subcategory-body .panel-three-subcategory-grid-list {
        grid-template-columns: repeat(4, 1fr);
    }
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 145px;
    }
}

@media only screen and (max-width: 991px) {
    .panelOne .body .panel-item-text {
        font-size: 18px;
    }
    .panelOne .body .panel-item-box {
        width: 220px;
        padding: 25px;
    }
    .panelOne .body .panel-item-subtitle {
        font-size: 15px;
    }
    .app_tabs .app_tab_item .tab_taxt {
        font-size: 16px;
    }
    .app_tabs .app_tab_item .tab_circle_text {
        font-size: 18px;
    }
    .app_tabs .app_tab_item_active {
        padding-top: 10px;
        padding-bottom: 10px;
    }
    .panelTwo .panel-two-subcatgory .panel-two-grid-list .panel-item-grid-item,
    .panelTwo .panel-two-subcatgory .panel-two-grid-list .panel-item-grid-item-active {
        height: 150px;
    }
    .panelTwo .panel-two-subcatgory .panel-two-grid-list .panel-item-grid-item .panel-item-grid-item-img,
    .panelTwo .panel-two-subcatgory .panel-two-grid-list .panel-item-grid-item-active .panel-item-grid-item-img {
        height: 145px;
    }
    .panelTwo .panel-two-subcatgory .panel-two-grid-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .panel-three .body .color-category-list-section {
        flex-wrap: wrap;
        justify-content: center;
    }
    .panel-three .body .sub-category-section {
        flex-direction: column;
    }
    .panel-selected-colors .panel-total-count-section {
        flex-wrap: wrap;
    }
    .panel-selected-colors {
        width: 100%;
    }
    .panel-selected-colors-body {
        width: 95%;
    }
    .panel-four .body .panel-four-sub-category-section {
        flex-direction: column;
    }
    .panel-four .body .panel-four-sub-category-section .panel-four-drow-secion .panel-four-draw-toolbar {
        min-width: 100%;
    }
}

@media only screen and (max-width: 767px) {
    .panel-two-toolbar {
        flex-direction: column;
        gap: 8px;
    }
    .panel-two-toolbar .panel-two-upload {
        margin-bottom: 10px;
    }
    .panelOne .body {
        flex-direction: column;
    }
    .panelOne .body .panel-item-box {
        width: 100%;
        margin-top: 20px;
    }
    .panelTwo .panel-two-body-content {
        padding: 32px 15px;
    }
    .panelTwo .panel-two-category-list {
        width: 30%;
    }
    .panelTwo .panel-two-category-active .panel-two-category-text,
    .panelTwo .panel-two-category .panel-two-category-text {
        font-size: 17px;
    }
}
@media only screen and (max-width: 550px) {
    .app_tabs {
        flex-direction: column;
    }
    .app_tabs .app_tab_item {
        margin-top: 10px;
    }
    .panel-two-toolbar {
        gap: 10px;
        flex-direction: column;
        align-items: flex-start;

        .panel-two-toolbar-btn-section {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: flex-start;
        }

        .panel-two-title {
            text-align: center;
        }
        .panel-two-upload-text-div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: center;
            align-items: center;
            align-self: stretch;
        }
        .panel-two-upload-btn-div {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            align-items: center;
        }
    }
    .panel-four-save-btn-div {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
    }

    .panelTwo .panel-two-body-content {
        flex-direction: column;
        min-height: auto;
        width: 100%;
    }
    .panelTwo .panel-two-category-list {
        gap: 5px;
        width: 100%;
        flex-direction: row;
        overflow-y: auto;
    }
    .panelTwo .panel-two-subcatgory .panel-two-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }
    .panel-three .body .sub-category-section {
        flex-direction: column;
    }
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item
        .panel-three-grid-item-title {
        bottom: auto;
    }
    .panel-three .body .sub-category-section .panel-three-subcategory-body .panel-three-subcategory-grid-list {
        grid-template-columns: repeat(4, 1fr);
    }
    .panel-selected-colors {
        width: 100%;
    }
    .panel-three .body .color-category-list-section {
        flex-flow: wrap;
        justify-content: center;
        gap: 5px;
    }
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item
        .panel-three-grid-code-section
        .panel-three-grid-item-code {
        font-size: 13px;
    }
    .panel-three .body .color-category-list-section .color-category-item {
        padding: 5px 10px;
    }
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 80px;
        height: 130px;
        padding: 10px;
    }
    .panel-three .body .search-section .search-box {
        width: 100%;
    }
    .panel-four .body .panel-four-sub-category-section .panel-four-drow-secion .panel-four-draw-toolbar {
        min-width: 100%;
        flex-wrap: wrap;
    }
    .panel-four .body .panel-four-sub-category-section {
        flex-direction: column;
    }
    .panel-four .body .panel-four-sub-category-section .panel-four-drow-secion {
        min-height: auto;
    }
    .panel-four
        .body
        .panel-four-sub-category-section
        .panel-four-drow-secion
        .panel-four-draw-toolbar
        .panel-four-tool-btn {
        width: 100%;
    }
    .panel-four
        .body
        .panel-four-sub-category-section
        .panel-four-drow-secion
        .panel-four-draw-toolbar
        .panel-four-center-section {
        width: 100%;
    }

    .page_heading {
        padding-top: 60px;
    }

    .panel-selected-colors-body {
        max-width: 90%;
        width: 90%;
    }

    .panelTwo .panel-two-category-active,
    .panelTwo .panel-two-category {
        flex-shrink: inherit;
    }

    // .water-mark-image {
    //     position: absolute;
    //     background-image: url('../assets/images/logo.png');
    //     background-repeat: no-repeat;
    //     background-size: contain;
    //     top: 10px;
    //     right: 10px;
    //     width: 50px;
    //     height: 30px;
    //     visibility: hidden;
    // }

    .panel-four .body .panel-four-sub-category-section .panel-four-drow-secion .panel-four-edit-section {
        width: 100%;
        margin-left: 32%;
    }
    .panel-four-save-btn-div {
        padding: 0px 20px 0px 20px;
    }

    .screenshot-bottom-section {
        padding-top: 10px;
        padding-left: 10px;

        .water-mark-image {
            height: 40px;
        }
        .screenshot-bottom-contact-group {
            gap: 8px;

            .screenshot-bottom-text-double {
                .s_icon_areas {
                    font-size: 16px;
                    height: 20px;
                    width: 20px;
                }
                .s_option_name {
                    font-size: 12px;
                    font-weight: 400;
                }
            }
        }
    }
}

@media only screen and (max-width: 481px) {
    .panel-three .body {
        padding: 32px 15px;
    }
    .panelTwo .panel-two-subcatgory .panel-two-grid-list .panel-item-grid-item .panel-item-grid-item-img {
        height: 150px;
    }
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 100px;
    }
    .panel-three .body .sub-category-section .panel-three-subcategory-body .panel-three-subcategory-grid-list {
        grid-template-columns: repeat(3, 1fr);
    }
    .panel-three .body .color-category-list-section .color-category-item {
        padding: 5px 6px;
    }
    .panel-four
        .body
        .panel-four-sub-category-section
        .panel-four-drow-secion
        .panel-four-draw-toolbar
        .panel-four-center-section,
    .panel-four
        .body
        .panel-four-sub-category-section
        .panel-four-drow-secion
        .panel-four-draw-toolbar
        .panel-four-tool-btn {
        width: 100%;
        padding: 12px 10px;
        gap: 5px;
        margin: 5px;
    }
}

@media only screen and (max-width: 381px) {
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 85px;
    }
}
@media only screen and (max-width: 350px) {
    .panel-three
        .body
        .sub-category-section
        .panel-three-subcategory-body
        .panel-three-subcategory-grid-list
        .panel-three-grid-item {
        width: 115px;
    }
    .panel-three .body .sub-category-section .panel-three-subcategory-body .panel-three-subcategory-grid-list {
        grid-template-columns: repeat(2, 1fr);
    }
}
