.color_select_area {
  font-family: "Raleway", sans-serif;
  border-radius: 0 15px 0 0;
  text-align: center;
  -webkit-transition: all 2s ease;
  -o-transition: all 2s ease;
  transition: all 2s ease;
  border: 1px solid $theme_color;
  border-radius: 0 0 5px 5px;

  @media (max-width: 767px) {
    border-radius: 0;
  }

  .color_select_header {
    background-color: #ffffff;
    padding: 0 15px;
    display: flex;
    align-items: center;
    gap: 10px;
    height: 52px;
    @media (max-width: 1199px) {
      padding: 0 15px;
    }
    .color_count {
      width: 52px;
      height: 35px;
      background-color: #2e323a;
      border-radius: 3px;
      font-size: 16px;
      text-align: center;
      line-height: 35px;
      color: #ffffff;
      font-weight: 600;
      span {
        padding-left: 5px;
      }
      &.active {
        background-color: #db1a28;
      }
    }
    .color_dsc {
      font-weight: 700;
      color: #000000;
      font-size: 14px;

      @media (max-width: 767px) {
        font-size: 13px;
      }
    }
  }

  .selected_color_area {
    padding: 15px;
    .view_changing_area {
      .primary_stage {
        color: #999;
        line-height: 20px;
        text-align: center;
        font-weight: 400;
        font-size: 14px;
        font-weight: 700;
        font-style: normal;
        padding-bottom: 15px;
      }

      .add_more_color {
        display: flex;
        gap: 15px;
        border-top: 1px solid #ccc;
        align-items: center;
        padding: 15px 10px;
        cursor: pointer;
        .plus_area {
          height: 38px;
          width: 38px;
          color: #db1a28;
          border: 1px dashed #db1a28;
          font-size: 16px;
          line-height: 37px;
        }
        .add_more_text {
          text-transform: uppercase;
          font-weight: 700;
          font-style: normal;
          font-size: 14px;
          line-height: 14px;
          color: #db1a28;
        }
      }
    }
    .selected_colors {
      border-top: 1px solid #ccc;
      // padding: 10px;
      &.no_border_top {
        border-top: none;
      }
      .single_color {
        display: flex;
        justify-content: space-between;
        border-bottom: 1px solid #ccc;
        padding: 10px 0;
        transition: all 0.5s ease-out;

        label {
          width: 100%;
        }

        .color_dscrip {
          cursor: pointer;
          text-align: left;
          display: flex;
          gap: 10px;

          align-items: center;
          .color_view {
            height: 40px;
            width: 40px;
            border-radius: 50%;
            text-align: center;
            position: relative;

            .checkmark {
              text-align: center;
              color: #2e323a;
              height: 20px;
              width: 20px;
              border-radius: 50%;
              background: $white;
              position: absolute;
              top: 10px;
              left: 10px;
              font-size: 14px;
            }
          }
          .title {
            font-size: 14px;
            line-height: 1;
            font-weight: 700;
            font-family: "Jost", sans-serif !important;

            .color_ppg {
              font-size: 12px;
              font-weight: 600;
            }
          }
        }
        .color_action {
          .delete_btn {
            height: 30px;
            width: 30px;
            border-radius: 50%;
            background: #b3301e;
            border: none;
            outline: none;
            font-size: 15px;
            padding: 5px;
            &:hover {
              background: #7e1f10;
            }
            &:focus {
              border: none;
              outline: none;
              box-shadow: none;
              background: #7e1f10;
            }
          }
        }
        @keyframes append-animate {
          from {
            transform: scaleY(0);
            opacity: 0;
          }
          to {
            transform: scaleY(1);
            opacity: 1;
          }
        }

        transform-origin: 50% 0;
        animation: append-animate 0.1s linear;
      }
    }
  }
}
